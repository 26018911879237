<template>
  <div class="list-info">
    <top-bar :title="'安全责任险管理'" :left="true"></top-bar>
    <div class="filterBar">
      <van-row>
        <van-col span="12">
          <p
            @click="dateBarShow = !dateBarShow"
            :style="{ color: dateBarShow ? '#387FF5' : '#666666' }"
          >
            投保时间
            <img :src="require(`@/assets/img/${dateBarShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="12">
          <p
            @click="userTypeShow = !userTypeShow"
            :style="{ color: userTypeShow ? '#387FF5' : '#666666' }"
          >
            {{ userType }}
            <img
              :src="
                require(`@/assets/img/${
                  userTypeShow ? 'pull-down-select' : 'pull-down'
                }.png`)
              "
              alt=""
              class="pull-icon"
            />
          </p>
        </van-col>
      </van-row>
    </div>
    <div class="dateBar" v-show="dateBarShow">
      <van-row>
        <van-col span="8" @click="beginDateShow = !beginDateShow"
          ><span>{{
            selectDate.beginDate == "" ? "最早" : selectDate.beginDate
          }}</span></van-col
        >
        <van-col span="2"><span>-</span></van-col>
        <van-col span="8" @click="endDateShow = !endDateShow"
          ><span>{{
            selectDate.endDate == "" ? "至今" : selectDate.endDate
          }}</span></van-col
        >
        <van-col span="3" @click="dateClose"><span>重置</span></van-col>
        <van-col span="3" @click="changeDate"><span>确定</span></van-col>
      </van-row>
    </div>
    <van-popup v-model="beginDateShow" position="bottom">
      <van-datetime-picker
        @confirm="beginDateConfim"
        @cancel="beginDateShow = false"
        type="date"
        :formatter="formatDate"
        :min-date="new Date(2017, 0, 1)"
        :max-date="new Date()"
      />
    </van-popup>
    <van-popup v-model="endDateShow" position="bottom">
      <van-datetime-picker
        @confirm="endDateConfim"
        @cancel="endDateShow = false"
        type="date"
        :formatter="formatDate"
        :min-date="new Date(2017, 0, 1)"
        :max-date="new Date(2050, 10, 1)"
      />
    </van-popup>
    <van-popup v-model="userTypeShow" position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="userTypeList"
        value-key="label"
        @confirm="userTypeConfirm"
        @cancel="userTypeShow = false"
      />
    </van-popup>
    <div class="addBtn" @click="goAdd">
      <img src="@/assets/img/add.png" alt="" />
    </div>
    <div class="cont">
      <!-- <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh"> -->
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getDataList"
          v-if="dataList.length > 0"
          offset="10"
        >
          <div class="totalCount">
            共有&nbsp;<span style="color: #387ff5">{{ totalCount }}</span
            >&nbsp;条数据
          </div>
          <div
            class="list-item"
            v-for="item in dataList"
            :key="item.id"
            @click="getInfo(item.id)"
          >
            <div class="title">
              <div class="title-left">
                {{ item.insuredName }}
              </div>
              <div class="title-right">
                <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              </div>
            </div>
            <div class="content">
              <img
                :src="require('@/assets/img/flower.png')"
                class="content-img"
              />

              <!-- <div>投保时间</div> -->
              <div class="content-text">
                <div class="top-text">{{ item.address }}</div>
                <div class="right-text" style="right:0">{{ item.insuredTypeStr }}</div>
                <div class="bottom-text" style="color:#387ff5">保费: {{ item.fee }}</div>
              </div>
              <van-icon name="arrow" color="#666" class="bottom-icon" />
            </div>
            <div class="content-bottom">投保时间: {{ item.insuredDate }}</div>
          </div>
        </van-list>
        <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      <!-- </van-pull-refresh> -->
    </div>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { mapMutations } from "vuex";
import {formatterDate} from '@/utils/utils'
export default {
  components: {
    topBar,
  },
  data() {
    return {
      page: 0,
      limit: 10,
      //时间
      selectDate: {
        endDate: "",
        beginDate: "",
      },
      //处理方式
      insuredType: "",
      userType: "被保人类型",
      type: "",
      loading: false,
      pullLoading: false,
      finished: false,
      endDateShow: false,
      beginDateShow: false,
      dateBarShow: false,
      userTypeShow: false,
      userTypeList: [
        { label: "所有", value: "0" },
        { label: "居民房屋", value: "1" },
        { label: "商户", value: "2" },
      ],
      totalCount: 0,
      dataList: [],
    };
  },
  methods: {
    ...mapMutations,
    getInfo(id) {
      this.$router.push({ path: "/insured-add", query: { id: id } });
    },
    getDataList() {
      this.page++;
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/insured/list"),
        method: "post",
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          insuredStartTime: this.selectDate.beginDate,
          insuredEndDates: this.selectDate.endDate,
          insuredType: this.type,
        }),
      }).then(
        ({ data }) => {
          if (data.code == 0) {
            this.pullLoading = false
            console.log(data)
            this.totalCount = data.page.totalCount;
            if (this.dataList.length == data.page.totalCount) {
              this.finished = true;
            }
            this.dataList = this.dataList.concat(data.page.list);
            // 加载状态结束
            this.loading = false;
            this.$toast.clear();
          }
          this.$toast.clear();
        },
        (err) => {
          this.$toast.clear();
        }
      );
    },
    formatDate(type, val) {
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      return val;
    },
    onPullDownRefresh(e) {
      this.finished = false
      this.dataList = []
      this.page = 0;
      this.getDataList()
    },
    onSearch() {
      this.page = 0;
      this.dataList = [];
      this.getDataList();
    },
    onCancel() {
      this.page = 0;
      this.dataList = [];
      this.searchValue = "";
      this.getDataList();
    },
    beginDateConfim(value) {
      let d = new Date(this.selectDate.endDate);
      if (
        this.selectDate.endDate != "" &&
        d.getTime(d) < value.getTime(value)
      ) {
        return this.$toast.fail("开始时间不能比结束时间大哦");
      }
      this.selectDate.beginDate = formatterDate(value);
      this.beginDateShow = false;
    },
    endDateConfim(value) {
      let d = new Date(this.selectDate.beginDate);
      if (
        this.selectDate.beginDate != "" &&
        d.getTime(d) > value.getTime(value)
      ) {
        return this.$toast.fail("结束时间不能比开始时间小哦");
      }
      this.selectDate.endDate = formatterDate(value);
      this.endDateShow = false;
    },
    changeDate() {
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    dateClose() {
      this.selectDate = {
        endDate: "",
        beginDate: "",
      };
      this.getDataList();
    },
    userTypeConfirm(value, index) {
      this.page = 0;
      this.dataList = [];
      this.userType = value.label;
      this.type = value.value == "0" ? "" : value.value;
      this.getDataList();
      this.userTypeShow = false;
    },
    goAdd() {
      this.$router.push("/insured-add");
    },
  },
  mounted() {
    this.$toast.loading({
      duration: 0,
      message: "加载中...",
      forbidClick: true,
    });
    this.userId = this.$globalData.userInfo.userId;
    this.getDataList();
  },
};
</script>
